import React from "react";

const Container = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <div className="lg:p-8">
      <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[650px]">
        {children}
      </div>
    </div>
  );
};

Container.displayName = "Container";

export { Container };
